import { AxiosError, AxiosResponse } from 'axios';
import { httpClient } from 'helpers/httpClient';
import { NewsDataResponse, NewsSingleDataResponse } from 'interfaces/News/News';

export const getNewsBackAdmin = async ({
  locale,
  geo,
  category = undefined,
}): Promise<AxiosResponse<NewsDataResponse>> => {
  try {
    const response = await httpClient.get<NewsDataResponse>('/news', {
      params: {
        locale,
        geo,
        category,
      },
    });

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};

export const getSingleNewsBackAdmin = async ({
  id,
  locale,
  geo,
}): Promise<AxiosResponse<NewsSingleDataResponse>> => {
  try {
    const response = await httpClient.get<NewsSingleDataResponse>(
      `/news/${id}`,
      {
        params: {
          locale,
          geo,
        },
      }
    );

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};
